@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animate-img:hover {
  animation: upDown 1s infinite;
}

@keyframes upDown {
  0%,
  100% {
    transform: scale(1.1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: scale(1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.yourActiveClass{
  background-color: lightskyblue;
  color:white
}