:root {
    --font-family: 'Roboto', sans-serif;
}

* {
    margin: 0;
    padding: 0;
}
body,
html {
    min-height: 100vh;
    background: #f1f3f6;
    font-family: var(--font-family) ;
}

.main-bg {
    background: url(../images/main-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}
.main-bg .holder {
    width: 700px;
}

@media screen and (max-width: 992px) {
    .main-bg {
        padding: 2rem;
    }
    .main-bg .holder {
        width: 100%;
    }
    .main-bg .holder h1 {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
    }
    .main-bg .holder > div {
        padding: 1.5rem;
    }
    .videoLoader {
        height: 380px;   
    }
}